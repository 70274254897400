/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@capacitor/storage';
import { concat, defer, forkJoin, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Content, Customtour, PointOfInterest } from 'src/app/models/FromApiModels';
import { PointInteretCategorie, SynchroReturnValue, UserInfos } from 'src/app/models/Models';
import { Storage as IonicStorage } from '@ionic/storage-angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  /**
   * Liste
   * - user: UserInfos
   * - token: String
   * - synchro: SynchroReturnValue
   * - synchroDate: number
   * - voyages: Customtour[]
   * - pointsOfInterest: PointOfInterest[]
   * - contents: Content[]
   */

  createDataBase = this.ionicStorage.create().then(e => true);

  hasBeenModified: Subject<{data: string; value: any}> = new Subject();

  constructor(
    private router: Router,
    private ionicStorage: IonicStorage
  ) { }

  // App


  getEnvironnementContext() {
    return defer(() => this.storageGet<string>('environnementContext'));
  }

  getEnvironnementBaseUrl() {
    return this.getEnvironnementContext().pipe(map(b => b === 'staging' ? environment.BASE_URL_STAGING: environment.BASE_URL));
  }

  getEnvironnementApiUrl() {
    return this.getEnvironnementContext().pipe(map(b => b === 'staging' ? environment.API_URL_STAGING: environment.API_URL));
  }

  setEnvironnementContext(context: 'staging' | 'prod') {
    return defer(() => this.storageSet<string>('environnementContext', context));
  }

  getToken() {
    return defer(() => this.storageGet<string>('token'));
  }

  setToken(token: string) {
    return defer(() => this.storageSet<string>('token', token));
  }

  getUser() {
    return defer(() => this.storageGet<UserInfos>('user'));
  }

  setUser(user: UserInfos) {
    return defer(() => this.storageSet<UserInfos>('user', user));
  }

  getSynchro() {
    return defer(() => this.storageGet<SynchroReturnValue>('synchro'));
  }

  setSynchro(synchro: SynchroReturnValue) {
    return defer(() => this.storageSet<SynchroReturnValue>('synchro', synchro));
  }

  getPointsInteretCategories() {
    return defer(() => this.storageGet<PointInteretCategorie[]>('pointsInteretCategories'));
  }

  setPointsInteretCategories(pointsInteretCategories: PointInteretCategorie[]) {
    return defer(() => this.storageSet<PointInteretCategorie[]>('pointsInteretCategories', pointsInteretCategories));
  }

  getVoyages() {
    return defer(() => this.storageGet2<Customtour[]>('voyages')).pipe(map(voyages => Array.isArray(voyages) ? voyages : []));
  }

  setVoyages(voyages: Customtour[]) {
    return defer(() => this.storageSet2<Customtour[]>('voyages', voyages));
  }

  removeVoyagesOriginal() {
    return defer(() => this.storageRemove<Customtour[]>('voyages'));
  }

  getPointsOfInterest() {
    return defer(() => this.storageGet<PointOfInterest[]>('pointsOfInterest')).pipe(map(pointsOfInterest => Array.isArray(pointsOfInterest) ? pointsOfInterest : []));
  }

  setPointsOfInterest(pointsOfInterest: PointOfInterest[]) {
    return defer(() => this.storageSet<PointOfInterest[]>('pointsOfInterest', pointsOfInterest));
  }

  getContents() {
    return defer(() => this.storageGet<Content[]>('contents')).pipe(map(contents => Array.isArray(contents) ? contents : []));
  }

  setContents(contents) {
    return defer(() => this.storageSet<Content[]>('contents', contents));
  }

  getSynchroDate() {
    return defer(() => this.storageGet<number>('synchroDate'));
  }

  setSynchroDate(synchroDate: number) {
    return defer(() => this.storageSet<number>('synchroDate', synchroDate));
  }

  getAppVersionlastSynchro() {
    return defer(() => this.storageGet<string>('appVersionLastSynchro'));
  }

  setAppVersionlastSynchro(appVersionlastSynchro: string) {
    return defer(() => this.storageSet<string>('appVersionLastSynchro', appVersionlastSynchro));
  }

  getLastSynchroWasSuccessful() {
    return defer(() => this.storageGet<boolean>('lastSynchroWasSuccessful'));
  }

  setLastSynchroWasSuccessful(lastSynchroWasSuccessful: boolean) {
    return defer(() => this.storageSet<boolean>('lastSynchroWasSuccessful', lastSynchroWasSuccessful));
  }

  getSetOfPresentFiles(): Observable<Set<string>> {
    return defer(() => this.storageGet<string[]>('setOfPresentFiles')).pipe(map(setOfPresentFiles => Array.isArray(setOfPresentFiles) ? new Set(setOfPresentFiles) : new Set()));
  }

  setSetOfPresentFiles(set: Set<string> | null) {
    const arrOfPresentFiles = set === null ? set : [...set];
    return defer(() => this.storageSet<string[]>('setOfPresentFiles', arrOfPresentFiles));
  }

  // Génériques

  storageSet<T>(key: string, value: any): Promise<T> {
    return Storage.set({key, value: JSON.stringify(value)})
    .then(_ => {
      this.hasBeenModified.next({data: key,value});
      return value;
    })
    .catch(err => {
      console.log(1, err, key, value)
      this.router.navigateByUrl('/ban');
      throw new Error(err);
    });
  }

  storageRemove<T>(key: string): Promise<void> {
    return Storage.remove({key});
  }

  storageGet<T>(key: string): Promise<T | null> {
    return Storage.get({key})
    .then(e => JSON.parse(e.value))
    .catch(err => {
      console.log(2)
      this.router.navigateByUrl('/ban');
      throw new Error(err);
    });
  }

  storageSet2<T>(key: string, value: T): Promise<T> {
    return this.ionicStorage.set(key, JSON.stringify(value))
    .then(_ => {
      this.hasBeenModified.next({data: key,value});
      return value;
    })
    .catch(err => {
      console.log(3)
      this.router.navigateByUrl('/ban');
      throw new Error(err);
    });
  }

  storageGet2<T>(key: string): Promise<T | null> {
    return this.createDataBase
    .then(_ => this.ionicStorage.get(key)
      .then(e => e !== null ? JSON.parse(e) as T : null)
      .catch(err => {
        throw new Error(err);
      })
    );
  }

  modif<T>(data: 'user' | 'token' | 'synchro' | 'synchroDate' | 'voyages' | 'contents' | 'environnementContext'): Observable<T> {
    return this.hasBeenModified.pipe(
      filter(e => e.data === data),
      map(e => e.value)
    );
  }

  observeSynchro() {
    return concat(
      this.getSynchro(),
      this.modif<SynchroReturnValue>('synchro')
    );
  }

  observeContents() {
    return concat(
      this.getContents(),
      this.modif<Content[]>('contents')
    );
  }

  observeVoyages() {
    return concat(
      this.getVoyages(),
      this.modif<Customtour[]>('voyages')
    );
  }

  observeEnvironnementContext() {
    return concat(
      this.getEnvironnementContext(),
      this.modif<string>('environnementContext')
    );
  }

  observeToken() {
    return concat(
      this.getToken(),
      this.modif<string>('token')
    );
  }

  purgeUserDatasLogOutObss() {
    return forkJoin([
      this.setUser(null),
      this.setToken(null),
      this.setSynchro(null),
      this.setSynchroDate(null),
      this.setVoyages(null),
      this.setPointsOfInterest(null),
      this.setContents(null),
      this.setEnvironnementContext(null),
      this.setPointsInteretCategories(null),
      this.setAppVersionlastSynchro(null),
      this.setLastSynchroWasSuccessful(null),
      this.setSetOfPresentFiles(null),
    ]);
  }
}

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { concatMap, filter, map, pairwise, pluck, switchMap } from 'rxjs/operators';
import { Content } from './models/FromApiModels';
import { UpdatedContent } from './models/Models';
import { DataService } from './services/data.service';
import { ContactService } from 'src/app/services/contact.service';
import { AlertController, IonTabs, NavController } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TripService } from './services/trip.service';
import { filterVoyage, formatDateIntoDate } from './utils/functions';
import { HttpService } from './services/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('tabs') tabs: IonTabs;
  public voyageEnCoursId: string = null;

  countryCode = this.contactService.countryCode;
  numero = this.contactService.numero;
  id$: Observable<string>;
  routerUrl = this.router.url;
  isMenuOpen = false;
  selectedTab = '';
  menuaff = false;
  lastId: string;

  environnementIsStaging$ = this.dataService.observeEnvironnementContext().pipe(
    switchMap(context => context === 'staging'
    ? this.dataService.observeToken().pipe(map(token => typeof token === 'string' && token.length > 0))
    : of(false)
    )
  );

  public appBottomPages = [
    {
      title: 'Accueil',
      url: '/home/trips',
      icon: 'home',
      //class: 'rotate45'
    },
    {
      title: 'Itinéraire',
      url: `/home/trip/${this.voyageEnCoursId}/day-by-day`,
      icon: 'analytics-outline',
      class: ''
    },
    {
      title: 'Carte',
      url: `/home/trip/${this.voyageEnCoursId}/carto`,
      icon: 'map',
      class: ''
    },
    {
      title: 'Réservations',
      url: `/home/trip/${this.voyageEnCoursId}/hebergements`,
      icon: 'ticket',
      class: 'ticket'

    },
    {
      title: 'Contact',
      url: '/home/contact',
      icon: 'person',
      class: ''
    },
  ];

  public optPages$ = combineLatest([
    this.dataService.observeSynchro().pipe(pluck('contents')),
    this.dataService.observeContents()
  ]).pipe(
    map(([synchroContents, contents]) => synchroContents ? this.integrateContents(synchroContents, contents) : { generic_contents: [], news: [] })
  );
  isLoginPage: boolean;
  isBanPage: boolean;
  browsingTrip: boolean;

  showMenu = false;

  userCanNote$ = this.dataService.observeVoyages().pipe(
    map(voyages => {
      if (!Array.isArray(voyages) || this.isBanPage) {
        return false;
      } else {
        return !this.isBanPage && voyages.some(voyage => {
          const date = new Date();
          const tripEndDate = new Date(formatDateIntoDate(voyage.end));

          return date > tripEndDate // Nous sommes après la date de fin
          && filterVoyage(voyage, 'validated') // Le voyage est validé
          && voyage.is_able_ranking === 1; // L'utilisateur est autorisé à noter
        });
      }
    })
  );

  appliVersion = environment.appVersion;

  constructor(
    private alertController: AlertController,
    private contactService: ContactService,
    private dataService: DataService,
    private http: HttpClient,
    private httpService: HttpService,
    private navController: NavController,
    public router: Router,
    public tripService: TripService,
  ) {
    this.dataService.removeVoyagesOriginal();
    this.voyageEnCoursId = null;
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentUrl = event.urlAfterRedirects;
        this.isLoginPage = (currentUrl === '/login' || currentUrl === '/launch');
        this.isBanPage = currentUrl === '/ban';
        this.browsingTrip = (currentUrl.indexOf('/home/trip/') !== -1 || currentUrl.indexOf('/home/contact') !== -1);
      }
    });
    let previousUrl: string;
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        pairwise()
      )
      .subscribe(([previousEvent, currentEvent]: [NavigationEnd, NavigationEnd]) => {
        previousUrl = previousEvent.urlAfterRedirects;
        const currentUrl = currentEvent.urlAfterRedirects;
        if (currentUrl === '/home/contact') {
          const idMatch = previousUrl.match(/\/trip\/(\d+)/);
          const id = idMatch ? idMatch[1] : null;
          this.voyageEnCoursId = id;
        } else {
          const idMatch = currentUrl.match(/\/trip\/(\d+)/);
          const id = idMatch ? idMatch[1] : null;
          this.voyageEnCoursId = id;
        }
      });
  }

  async confirmDeleteAccount() {
    const alert = await this.alertController.create({
      header: 'Confirmation',
      message: 'Voulez-vous vraiment supprimer votre compte ?',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'OK',
          handler: () => {
            // Fonction à exécuter lorsque l'utilisateur appuie sur le bouton OK
            this.deleteAccount();
            // this.router.navigate(['/chemin-de-la-route']);

          },
        },
      ],
    });

    await alert.present();
  }
  async deleteAccount() {
    const tokenPromise = this.dataService.storageGet<string>('token');

    tokenPromise.then((token) => {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`
      });
      this.httpService.uriFrom({uriTail: `delete-account`}).pipe(
        concatMap(uri => this.http.post(uri, null, { headers }))
      ).subscribe(
        (response) => {
          // console.log(response);
          this.router.navigate(['/logout']);
        },
        (error) => {
          console.error(error);
          // Handle any errors
        }
      );
    });
  }
  goBack() {
    // Code pour revenir à la page précédente
    this.navController.back();
  }

  integrateContents(synchroContents: { generic_contents: UpdatedContent[]; news: UpdatedContent[] }, contents: Content[]): { generic_contents: Content[]; news: Content[] } {
    const contentsMap = contents.reduce((acc, content) => acc.set(content.id, content), new Map());
    const rreduce = (acc, cur: Content) => contentsMap.has(cur.id)
      ? [...acc, contentsMap.get(cur.id)]
      : acc;
    return {
      generic_contents: synchroContents.generic_contents.reduce(rreduce, []),
      news: synchroContents.news.reduce(rreduce, [])
    };
  }

  menu() {
    this.menuaff = !this.menuaff;
  }

  openPage(title: string) {
    this.selectedTab = title;
    if (title === 'Accueil') {
      this.navController.navigateForward('/home/trips');
    }
    if (title === 'Itinéraire') {
      this.navController.navigateForward(`/home/trip/${this.voyageEnCoursId}/day-by-day`);
    }
    if (title === 'Carte') {
      this.navController.navigateForward(`/home/trip/${this.voyageEnCoursId}/carto`);
    }
    if (title === 'Réservations') {
      this.navController.navigateForward(`/home/trip/${this.voyageEnCoursId}/hebergements`);
    }
    if (title === 'Contact') {
      this.navController.navigateForward('/home/contact');
    }

  }

  openWhatsApp() {
    const countryCode = this.contactService.countryCode;
    const numero = this.contactService.numero;
    const url = `https://api.whatsapp.com/send?phone=${countryCode}${numero}`;
    window.open(url, '_blank');

  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

}

@Pipe({
  name: 'hasMatchingURL'
})
export class HasMatchingURLPipe implements PipeTransform {
  transform(url: string, routerUrl: string): boolean {
    const currentUrlWithoutQuery = routerUrl.split('?')[0];
    const navigationUrlWithoutQuery = url.split('?')[0];
    const currentUrlEnding = currentUrlWithoutQuery.substring(currentUrlWithoutQuery.lastIndexOf('/') + 1);
    const navigationUrlEnding = navigationUrlWithoutQuery.substring(navigationUrlWithoutQuery.lastIndexOf('/') + 1);
    return currentUrlEnding === navigationUrlEnding;
  }
}
